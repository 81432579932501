import './app.scss';
// import SlimSelect from 'slim-select';
import domready from 'domready';
import 'lazysizes';
import { SummaryHandler } from './services/summery-handler';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import AjaxLoad from './utilities/js/ajax-load';
import { ResizeHandler } from './utilities/js/resize-handler';
import { EventScroller } from './utilities/js/event-scroller';
import { trackingFocusEvent, trackingSend, checkFormInputs } from './utilities/js/dl-Tracking';

import { loadYoutubeApi, loadGoogleApi } from './utilities/js/helper';

window.OPTIONS = {
    // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    boundsOffset: 100,
    easing: 'easeInOut',
    loading:
        '<b class="loading loading--show"><b class="loader"><i>loading...</i></b></b>',
    speed: 500
};

// init summary instance and pass to accessories, e-device slider, etc
// to use only one instance instead each module creating its own
const $summary = document.querySelector('[data-summary="root"]');
if ($summary) {
    window.summaryHandler = new SummaryHandler($summary);
}

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();
window.youtubeApi = false;
window.youtubeApiLoading = false;
window.youtubeReady = false;
window.googleApiLoader = null;
window.googleApiReady = false;
window.studioFinderLoaded = false;

const inViewObserver = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            if (!entry.target.classList.contains('inview')) {
                entry.target.classList.add('inview');
                window.inViewObserver.unobserve(entry.target);

                if (typeof entry.target.dataset.videoplayer !== 'undefined') {
                    if (!window.youtubeApiLoading) {
                        if (typeof window.__ucCmp !== 'undefined') {
                            window.__ucCmp.getConsentDetails().then((detail) => {
                                const youtubeStatus = detail.services['BJz7qNsdj-7'].consent.given; // eslint-disable-line

                                if (youtubeStatus) {
                                    loadYoutubeApi();
                                }

                                window.onYouTubeIframeAPIReady = () => {
                                    window.youtubeReady = true;
                                    const youtubeApiLoadedEvent = new CustomEvent('youtubeApiLoaded', {
                                        view: window,
                                        bubbles: true,
                                        cancelable: false,
                                        detail: {
                                            $context: document
                                        }
                                    });

                                    window.dispatchEvent(youtubeApiLoadedEvent);
                                };
                            });
                        }
                    }
                }

                if (typeof entry.target.dataset.studioFinder !== 'undefined') {
                    if (!window.googleApiReady) {
                        window.__ucCmp.getConsentDetails().then((detail) => {
                            const googleMapStatus = detail.services['S1pcEj_jZX'].consent.given; // eslint-disable-line

                            if (googleMapStatus) {
                                loadGoogleApi();
                            }
                        });
                    }
                }
            }
        }
    });
};

window.initContent = ($content) => {
    loadLazyImages();

    window.inViewObserver = new IntersectionObserver(inViewObserver, {
        threshold: 0.0,
        rootMargin: '15%'
    });

    // init youtubeAPI if in view
    const $youtubeVideos = document.querySelectorAll('[data-videoplayer]');
    setTimeout(() => {
        $youtubeVideos.forEach((youtubeVideo) => {
            window.inViewObserver.observe(youtubeVideo);
        });
    }, 500);

    // Loading Google API if focus on Google Autocomplete Input
    const $subSwitch = document.querySelector('[data-sub-switch="mobile-location"]');
    if ($subSwitch) {
        $subSwitch.addEventListener('click', () => {
            if (!window.googleApiReady) {
                if (!window.studioFinderLoaded) {
                    import('./modules/studio-finder/studio-finder').then(() => {
                        loadGoogleApi();
                    });
                } else {
                    loadGoogleApi();
                }
            }
        });
    }

    trackingFocusEvent($content);

    // init formfields
    const allFields = $content.querySelectorAll('select, input:not([type="hidden"]), textarea');
    if (allFields.length > 0) {
        import('./components/form-fields/form-fields.js').then(({ FormFields }) => {
            for (let i = 0; i < allFields.length; i++) {
                const $field = new FormFields(allFields[i], {
                    onLoaded: ($sidebarContent) => { window.initContent($sidebarContent); }
                });
                $field.initialize();
            }
        });
    }

    // siteheader website
    const $siteHeader = $content.querySelector('[data-site-header="root"]');
    if ($siteHeader) {
        import('./layout/site-header/siteheader').then(({ SiteHeader }) => {
            // eslint-disable-next-line no-new
            new SiteHeader($siteHeader, {
                // eslint-disable-line
                scrollHandler: window.eventScroller
            }); // eslint-disable-line
        });
    }

    // init sidebar
    const $sidebars = $content.querySelectorAll('[data-sidebar-handle]');
    if ($sidebars) {
        import('./components/sidebar/sidebar').then(({ Sidebar }) => {
            for (let i = 0; i < $sidebars.length; i++) {
                const sideBarAPI = new Sidebar($sidebars[i], {
                    loading: window.OPTIONS.loading,
                    onLoaded: ($sidebarContent) => { window.initContent($sidebarContent); }
                });
                sideBarAPI.initialize();
            }
        });
    }

    // init panorama
    const $$panorama = $content.querySelectorAll('[data-panorama]');
    if ($$panorama) {
        import('./components/panorama/panorama').then(({ Panorama }) => {
            for (let i = 0; i < $$panorama.length; i++) {
                const panoramaAPI = new Panorama($$panorama[i], {
                    loading: window.OPTIONS.loading,
                    onLoaded: ($panoramaContent) => { window.initContent($panoramaContent); }
                });
                panoramaAPI.initialize();
            }
        });
    }

    // init pop-up-content
    const $$popUp = $content.querySelectorAll('[data-pop-up-content="root"]');
    if ($$popUp) {
        import('./components/pop-up-content/pop-up-content').then(({ PopUp }) => {
            for (let i = 0; i < $$popUp.length; i++) {
                const ppopUpAPI = new PopUp($$popUp[i], {
                    loading: window.OPTIONS.loading,
                    onLoaded: ($popUpContent) => { window.initContent($popUpContent); }
                });
                ppopUpAPI.initialize();
            }
        });
    }

    // init feed
    const $$feed = $content.querySelectorAll('[data-feed="root"]');
    if ($$feed) {
        import('./modules/feed/feed').then(({ Feed }) => {
            for (let i = 0; i < $$feed.length; i++) {
                const feedAPI = new Feed($$feed[i], {
                    loading: window.OPTIONS.loading,
                    onSubmitted: ($results) => {
                        window.initContent($results);
                    }
                });
                feedAPI.initialize();
            }
        });
    }

    // init action-handler
    const $$ActionHandler = $content.querySelectorAll('[data-action]');
    if ($$ActionHandler.length > 0) {
        import('./modules/action/action').then(({ ActionHandler }) => {
            for (let i = 0; i < $$ActionHandler.length; i++) {
                const $ActionHandler = new ActionHandler($$ActionHandler[i]);
                $ActionHandler.initialize();
            }
        });
    }

    // reset Form
    const $formResets = $content.querySelectorAll('[data-form-reset]');
    $formResets.forEach(formReset => {
        const $form = formReset.closest('form');

        formReset.addEventListener('click', e => {
            e.preventDefault();
            $form.reset();

            // Check if Form got a RangeSlider and Reset to Default Values
            const $rangeSliderFrom = document.querySelector('[data-filter-rangefrom]');
            const $rangeSliderTo = document.querySelector('[data-filter-rangeto]');
            if ($rangeSliderFrom) {
                $rangeSliderFrom.value = $rangeSliderFrom.getAttribute('data-filter-rangefrom');
                $rangeSliderTo.value = $rangeSliderTo.getAttribute('data-filter-rangeto');
            }

            const checkboxes = $form.querySelectorAll('input[type="checkbox"]');

            checkboxes.forEach(cb => {
                cb.checked = false;
            });

            $form.submit();
        });
    });

    // init password show
    $content.querySelectorAll('[data-password-show]').forEach(password => {
        const passwordShowHandle = password.getAttribute('data-password-show');
        const $passwordShowHandle = password.parentNode.querySelector(passwordShowHandle);

        $passwordShowHandle.addEventListener('click', (e) => {
            const passwordShowType = password.getAttribute('type');

            if (passwordShowType === 'password') {
                password.setAttribute('type', 'text');
                $passwordShowHandle.classList.add('is--active');
            } else {
                password.setAttribute('type', 'password');
                $passwordShowHandle.classList.remove('is--active');
            }

            e.preventDefault();
        });
    });

    // init filter
    const $filters = $content.querySelectorAll('[data-filter]');
    import('./modules/filter/filter').then(({ Filter }) => {
        for (let i = 0; i < $filters.length; i++) {
            const $filter = new Filter($filters[i]);
            $filter.initialize();
        }
    });

    // Init Scroller (Anchor Navigation)
    const $allScroller = $content.querySelectorAll('[data-scroller]');
    if ($allScroller) {
        import('./modules/scroller/scroller').then(({ Scroller }) => {
            $allScroller.forEach(scroller => {
                const $scroller = new Scroller(scroller);
                $scroller.initialize();
            });
        });
    }

    // init loader
    const $$loader = $content.querySelectorAll('[data-loader]');
    if ($$loader) {
        import('./services/loader').then(({ Loader }) => {
            for (let i = 0; i < $$loader.length; i++) {
                const loaderAPI = new Loader($$loader[i], {
                    loading: window.OPTIONS.loading,
                    onSubmitted: ($results) => {
                        window.initContent($results);
                    }
                });
                loaderAPI.initialize();
            }
        });
    }

    // init teaser-collection
    const $$teaserCollection = $content.querySelectorAll('[data-teaser-collection="root"]');
    if ($$teaserCollection) {
        import('./modules/teaser-collection/teaser-collection').then(({ TeaserCollection }) => {
            for (let i = 0; i < $$teaserCollection.length; i++) {
                const teaserCollectionAPI = new TeaserCollection($$teaserCollection[i], {
                    loading: window.OPTIONS.loading,
                    onSubmitted: ($results) => {
                        window.initContent($results);
                    }
                });
                teaserCollectionAPI.initialize();
            }
        });
    }

    // init feed
    const $ajaxforms = $content.querySelectorAll('[data-ajaxform]');
    if ($ajaxforms) {
        import('./modules/ajax-form/ajax-form').then(({ Ajaxform }) => {
            for (let i = 0; i < $ajaxforms.length; i++) {
                const ajaxFormAPI = new Ajaxform($ajaxforms[i], {
                    loading: window.OPTIONS.loading,
                    onSubmitted: ($results) => {
                        window.initContent($results);

                        // add content listener
                        const contentUpdateEvent = new CustomEvent('content.loaded', {
                            view: window,
                            bubbles: true,
                            cancelable: false,
                            detail: {
                                $context: $results
                            }
                        });

                        window.dispatchEvent(contentUpdateEvent);
                    }
                });
                ajaxFormAPI.initialize();
            }
        });
    }

    const $overlay = document.querySelector('[data-overlay]');
    if ($overlay) {
        import('./components/overlay/overlay').then(({ Overlay }) => {
            const $$overlay = new Overlay($overlay);
            $$overlay.initialize();
        });
    }
};

domready(function () {
    const initApplication = () => {
        window.initContent(document);

        // add content listener
        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);
    };

    window.eventScroller.init();
    window.resizeHandler.init();

    if (!window.Modernizr.csshover) {
        document.querySelectorAll('[data-hover]').forEach((item) => {
            const hoverClass = item.getAttribute('data-hover');

            item.addEventListener('touchstart', () => {
                if (item.classList.contains(hoverClass)) {
                    item.classList.remove(hoverClass);
                } else {
                    item.classList.add(hoverClass);
                }
            }, { passive: true });

            document.addEventListener('touchstart', (e) => {
                const $target = e.target;

                if (item.contains($target)) {
                    item.classList.remove(hoverClass);
                }
            }, { passive: true });
        });
    }

    initApplication();

    window.addEventListener('DOMContentLoaded', (event) => {
        if (document.referrer.includes('/account/login')) {
            if (!window.location.pathname.includes('/account/registrierung') && window.location.pathname !== '/account/') {
                trackingSend({
                    event: 'form_abandonment',
                    formName: 'Login',
                    context: window.dataLayer[0]?.page?.customPage ?? window.location.pathname
                });
            }
        }
    });

    window.addEventListener('beforeunload', (event) => {
        const $$forms = document.querySelectorAll('form[data-form-name]:not(form[data-form-name="Newsletter"])');

        if ($$forms.length > 0) {
            $$forms.forEach(($form) => {
                const formName = $form.dataset.formName;
                const customPage = window.dataLayer[0]?.page?.customPage ?? window.location.pathname;

                if (formName !== 'Login') {
                    if (formName !== 'Beratung') {
                        trackingSend({
                            event: 'form_abandonment',
                            formName,
                            status: checkFormInputs($form),
                            context: customPage
                        });
                    } else {
                        trackingSend({
                            event: 'form_abandonment',
                            formName,
                            context: customPage
                        });
                    }
                }
            });
        }
    });

    const notifyCookiebotIdChange = () => {
        new AjaxLoad('/cookie-consent/update', {}); // eslint-disable-line
    };

    // bind cookiebot event handler, when user changes preferences
    window.addEventListener('CookiebotOnAccept', () => {
        notifyCookiebotIdChange();
    });

    window.addEventListener('CookiebotOnDecline', () => {
        notifyCookiebotIdChange();
    });
});
