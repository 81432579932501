const trackingFocusEvent = ($content) => {
    const $$trackingElements = $content.querySelectorAll('[data-tracking-focus]');

    const addEvent = ($trackingElement) => {
        $trackingElement.addEventListener('focus', () => {
            const formName = $trackingElement.dataset.trackingFocus;
            const customPage = window.dataLayer[0]?.page?.customPage ?? window.location.pathname;

            if (formName) {
                trackingSend({
                    event: 'form_view',
                    formName,
                    context: customPage
                });
            }
        });
    };

    $$trackingElements.forEach(($trackingElement) => {
        addEvent($trackingElement);
    });
};

const trackingSend = (data) => {
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(data);
    }
};

const checkFormInputs = ($form) => {
    const $$inputs = $form.querySelectorAll('input, select');
    let allFilled = true;
    let noneFilled = true;
    const radioGroups = {};

    $$inputs.forEach((input) => {
        if (!input.parentElement.className.includes('visuallyhidden')) {
            if (input.type === 'text' || input.type === 'email' || input.type === 'password' || input.tagName.toLowerCase() === 'select') {
                if (input.value.trim() === '') {
                    allFilled = false;
                } else {
                    noneFilled = false;
                }
            } else if (input.type === 'checkbox') {
                if (input.checked) {
                    noneFilled = false;
                } else {
                    allFilled = false;
                }
            } else if (input.type === 'radio') {
                if (!radioGroups[input.name]) {
                    radioGroups[input.name] = { filled: false, anyChecked: false };
                }
                if (input.checked) {
                    radioGroups[input.name].anyChecked = true;
                    noneFilled = false;
                }
            }
        } else {
            console.log('visually Hidden', input);
        }
    });

    for (const group in radioGroups) {
        if (!radioGroups[group].anyChecked) {
            allFilled = false;
        }
    }

    if (noneFilled) {
        return 'empty';
    } else if (allFilled) {
        return 'full';
    } else {
        return 'partial';
    }
};

export {
    trackingFocusEvent,
    trackingSend,
    checkFormInputs
};
